<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import moment from 'moment'
  import axios from "axios";
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    page: {title: "EINSTUDIO-Files",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader, vueDropzone: vue2Dropzone
    },data() {
      return {
        title: "FILES",
        items: [{text: "Home",href:"/"}, {text: "Files",href:'/myfiles'}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,user:[],files:[],groupId:"",uploadedFiles: [],fileindex:0,fileArray:[],name:"",userId:"",
        dropzoneOptions: {
          url: "https://httpbin.org/post",
          thumbnailWidth: 200,
          maxFilesize: 25,
          timeout: 180000,
          parallelUploads: 100,
          addRemoveLinks: true,
          acceptedFiles: ".img,.jpg,.png,.gif,.pptx,.pdf,.xlsx,.docx",
          accept: this.checkDupl
        }
      };
      },created:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.loadData();
      },methods: {
         addData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("add-file-form"));

         formToPost.append("userId",this.userId)
         formToPost.append("status",true)
         formToPost.append("groupId",this.groupId)
         formToPost.append("name",this.name)
         

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }
         axios.post(`${this.baseUrl}/Group/File/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
          this.hideModal('modal-lg');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },loadData(){
        let loader=this.$loading.show();
        let name=this.$route.params.name ;
        let url= this.baseUrl+"/Group/File/GetAll?status=true&pagesize=0&pageindex=0&key="+name;
        // if(this.user.rol!='admin')
        // url=url+"&userId="+this.user.id

          axios.get(url,{
            headers:{ Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{

            if(response.data.length>0)
            {
              this.fileArray= response.data[0].files;
              this.userId=response.data[0].userId;
              this.groupId=response.data[0].groupId;
              this.name=response.data[0].category;
              this.title='Files /'+ response.data[0].category;
              this.items[2]=response.data[0].category
            }
            else{
                this.$router.push('/myfiles');
            }

            loader.hide();

          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },dropzoneLoadedFile(file) {
        this.uploadedFiles.push(file);
      },checkDupl(file, done) {
        let v = this.$refs.FileDropzone;
        let files = v.getAcceptedFiles()
        let item = files.find( el => el.upload.filename === file.name )
        if (item) {
          done("duplicate")
        } else {
          done()
        }
      },format_date(value)
      {
         if (value) 
         {
           return moment(String(value)).format('MM.DD.YYYY HH:mm')
          }
      },deleteConfirm(id){
        this.$swal({ 
          title: "WARNING",
          text: "Are you sure? Will be deleted along with the connected data.",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes",
          cancelButtonText:"No",
          }).then((result) => {
            if (result.value) 
            {
              this.fileDelete(id)
            }
          });
      },fileDelete(fileid){
        let loader= this.$loading.show();
        axios.delete(`${this.baseUrl}/Group/File/Delete/`+fileid, {
            headers: { Authorization: `Bearer ${this.user.jwt}`
          },
        }).then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
          
        })
        .catch((error) => 
        {
          loader.hide();
          var html ="<p class='small'>"+ error.response.data.message+"</p>";
          this.$swal("",html,"error");
        });
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
     }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3" v-if="user.rol=='admin' | user.rol=='teacher'">
        <div class="col-lg-12">
          <a type="button" id="add-modal"  v-b-modal.modal-lg  class="btn btn-success"><i class="fa fa-envelope"></i> + ADD NEW FILES</a>
        </div>
    </div>
    <div id="files-div" class="row">
      <div class="col-2" v-for="(item, index) in fileArray" :key="index">
            <div class="card">
                    <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                     
                             <p><i class="fa fa-file"> </i> {{item.name}} </p>
                          
                            <img v-if="(item.fileExtension=='.jpg'| item.fileExtension=='.png' | item.fileExtension=='.JPG'| item.fileExtension=='.PNG')" :src="fileUrl+'/files/images/groups/files/'+item.basePath+'/'+item.subPath+'/'+item.name" class="cover-image" />
                            <img v-if="(item.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image" />
                            <img v-if="(item.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image" />
                            <img v-if="(item.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image" />
                            <img v-if="(item.fileExtension=='.pptx')" :src="fileUrl+'/files/images/ppt.png'" class="cover-image" />
                        </div>
                    </div>
                </div>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <a  v-b-tooltip.hover title="Open" class="btn btn-sm btn-info mr-1" :href="(fileUrl+'/files/images/groups/files/'+item.basePath+'/'+item.subPath+'/'+item.name)" target="_blank"><i class="fa fa-eye"></i></a>
                        <a type="button" v-b-tooltip.hover title="Delete" v-if="(user.rol=='admin' | (user.rol=='teacher' && item.userId==user.id))"  class="btn btn-sm btn-danger" @click="deleteConfirm(item.id)"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" title="New Group Items" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-file-form">
            <div class="row">
                <div class="col-sm-12">
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE FILES</button>
                </div>
            </div>
          
        </form>
    </b-modal>
  </Layout>
</template>